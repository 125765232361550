import { MonitoringInit } from 'js/components/Monitoring/Monitoring.js';
import { Polyfills } from 'js/components/Polyfills/Polyfills.js';
import { Instance } from 'js/components/YextAnalytics/Analytics.js';
import { AccessibilityChecks } from 'js/components/Util/Accessibility.js';
import { OnReady } from 'js/components/Util/Browser.js';
import { ImageObjectFit } from 'js/components/Image/index.js';
import { Debug } from "ts/components/Util/Debug.ts";
import { AnalyticsDebugger } from "ts/components/AnalyticsDebugger/AnalyticsDebugger.ts";
import { Cookies } from 'js/components/Cookies/index--override.js';
import URI from 'urijs';
import LazyLoad from 'node_modules/vanilla-lazyload/dist/lazyload.min.js';

import 'script-loader!node_modules/svg4everybody/dist/svg4everybody.min.js';
import { AjaxHeaderFooter } from './modules/AjaxHeaderFooter';

export class Global {
  static init() {
    Polyfills.init();
    MonitoringInit();

    OnReady(() => {
      new AjaxHeaderFooter();
      const lazyLoader = new LazyLoad({
        elements_selector: '.js-lazy',
      });

      Yext.Analytics = Instance;

      const thisYear = new Date().getFullYear();
      const dateSpans = document.querySelectorAll('.c-copy-date');
      for (const dateSpan of dateSpans) {
        dateSpan.innerHTML = `&copy; ${thisYear}`;
      }

      ImageObjectFit();
      svg4everybody();

      if (Yext.isStaging ||
          window.location.href.includes('yextpages') ||
          window.location.href.includes('localhost')) {
        AccessibilityChecks.checkAltTags();
      }

      if (Debug.isEnabled()) {
        window.Debugger = new AnalyticsDebugger();
      }

      const cookieDomain = window.location.href.includes('localhost')
        ? "localhost"
        : Yext.isStaging === 'true'
        ? ".local_lendnation_com.yextpages.net"
        : ".lendnation.com";

      if (Yext.stateName) {
        Cookies.setCookie('LNState', Yext.stateName, 30, '/', cookieDomain);
      }

      const setCookie = function({ utm_var = 'none' } = {}) {
        const referrer = document.referrer;

        // don't set a cookie if coming from corporate site; we're assuming the main site has set it already
        if (Cookies.getCookie('utm_tags') ||
          referrer == 'https://www.lendnation.com' ||
          referrer == 'https://www.lendnation.com/') {
          return;
        }

        let cookie = {};
        const utmSourceMapping = {
          'android.googlequicksearchbox': 'Google',
          'bing.com': 'Bing',
          'google': 'Google',
          'google.com': 'Google',
          'yahoo.com': 'Yahoo'
        }
        const uriParams = new URI(window.location).search(true);
        const utm_source = (uriParams.utm_source && utmSourceMapping[uriParams.utm_source.toLowerCase()]) ? utmSourceMapping[uriParams.utm_source.toLowerCase()] : uriParams.utm_source;
        const utm_campaign = uriParams.utm_campaign;
        const utm_content = uriParams.utm_content;
        const utm_term = uriParams.utm_term;
        const utm_medium = uriParams.utm_medium;
        const searchSites = ['google', 'bing', 'yahoo', 'duckduckgo', 'ask'];

        // always have leading zero by using padStart
        const date = new Date();
        const year = date.getUTCFullYear()
        const month = `${date.getUTCMonth() + 1}`.padStart(2, 0)
        const day = `${date.getUTCDate()}`.padStart(2, 0)
        const cookieDate = year + month + day;

        // these are always the same
        cookie.utm_var = utm_var;
        cookie.Date = cookieDate;

        if (uriParams.gclid || uriParams.GCLID) {
          cookie.utm_source = 'Google';
          cookie.utm_medium = 'PPC';
          cookie.utm_campaign = utm_campaign || 'none';
          cookie.utm_content = utm_content || 'none';
          cookie.utm_term = utm_term || 'none';
        } else if ((referrer == 'https://www.google.com/' || referrer == 'https://google.com') && !uriParams.gclid) {
          cookie.utm_source = 'Google';
          cookie.utm_medium = 'Organic';
          cookie.utm_campaign = utm_campaign || 'none';
          cookie.utm_content = utm_content || 'none';
          cookie.utm_term = utm_term || 'none';
        } else if (uriParams.msclkid || uriParams.MSCLKID) {
          cookie.utm_source = 'Bing';
          cookie.utm_medium = 'PPC';
          cookie.utm_campaign = utm_campaign || 'none';
          cookie.utm_content = utm_content || 'none';
          cookie.utm_term = utm_term || 'none';
        } else if (utm_medium && ['cpc', 'ppc'].some(str => utm_medium.toLowerCase() == str.toLowerCase())) {
          cookie.utm_campaign = utm_campaign || 'none';
          cookie.utm_content = utm_content || 'none';
          cookie.utm_medium = utm_medium;
          cookie.utm_source = utm_source || 'none';
          cookie.utm_term = utm_term || 'none';
        } else if (utm_source && !searchSites.some(str => utm_source.toLowerCase() === str.toLowerCase())) {
          cookie.utm_campaign = utm_campaign || 'none';
          cookie.utm_content = utm_content || 'none';
          cookie.utm_medium = utm_medium || 'none';
          cookie.utm_source = utm_source;
          cookie.utm_term = utm_term || 'none';
        } else if (utm_source && searchSites.some(str => utm_source.toLowerCase() === str.toLowerCase())) {
          cookie.utm_campaign = 'none';
          cookie.utm_content = 'none';
          cookie.utm_medium = 'Organic';
          cookie.utm_source = utm_source;
          cookie.utm_term = 'none';
        } else if (utm_campaign) {
          cookie.utm_campaign = utm_campaign;
          cookie.utm_content = utm_content || 'none';
          cookie.utm_medium = utm_medium || 'none';
          cookie.utm_source = utm_source || 'none';
        } else if (referrer) {
          const referrerUri = new URI(referrer);
          cookie.utm_campaign = 'none';
          cookie.utm_content = referrer;
          cookie.utm_medium = 'Referral';
          cookie.utm_source = referrerUri.domain();
          cookie.utm_term = 'none';
        } else {
          cookie.utm_campaign = 'none';
          cookie.utm_content = 'none';
          cookie.utm_medium = 'none';
          cookie.utm_source = 'Direct';
          cookie.utm_term = 'none';
        }

        Cookies.setCookie('utm_tags', JSON.stringify(cookie), 30, '/', cookieDomain, true);
      };

      ga(function() {
        var cookie_dic = {};
        document.cookie.split(';').forEach(function(el) {
          var splitCookie = el.split('=');
          var key = splitCookie[0].trim();
          var value = splitCookie[1];
          cookie_dic[key] = value;
        });
        const utm_var = cookie_dic["_ga"].substring(6);
        setCookie({ utm_var });
      });

      const applyNowButton = document.querySelector('.Header-link--applynow');
      if (applyNowButton) {
        applyNowButton.addEventListener('click', () => {
          ga('gtag_UA_119749604_1.send', 'event', 'Header', 'applyNow');
        });
      }
    });
  }
}
