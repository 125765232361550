export class AjaxHeaderFooter {
  constructor() {
    const isStaging = (window.location.href.includes('yextpages') || window.location.href.includes('localhost'));
    this.initHeader(isStaging);
    this.initFooter(isStaging);
  }

  initHeader(isStaging) {
    let endpoint = isStaging ? 'https://www.ppqchi.com/api/embed/v1/templates/header' : 'https://www.lendnation.com/api/embed/v1/templates/header';

    const headerContainer = document.getElementById('Header');

    try {
      fetch(endpoint).then(res => {
        if (res) {
          return res.text();
        }
      })
      .then((responseText) => {
        if (responseText) {
          this.fetchContent(responseText, headerContainer);
        }
      });
    } catch (err) {
      console.error(`Fetching header at ${endpoint} failed: ${err}`)
    }
  }


  initFooter(isStaging) {
    let endpoint = isStaging ? 'https://www.ppqchi.com/api/embed/v1/templates/footer' : 'https://www.lendnation.com/api/embed/v1/templates/footer';

    const footerContainer = document.getElementById('Footer');

    try {
      fetch(endpoint).then(res => {
        if (res) {
          return res.text();
        }
      })
      .then((responseText) => {
        if (responseText) {
          this.fetchContent(responseText, footerContainer);
        }
      });
    } catch (err) {
      console.error(`Fetching footer at ${endpoint} failed: ${err}`)
    }
  }

  fetchContent(html, element) {
    const parser = new DOMParser();
    const clientDoc = parser.parseFromString(html, 'text/html');
    const head = clientDoc.head;

    for (const script of document.querySelectorAll('script')) {
      if (script.type == 'application/ld+json') {
        continue;
      }

      script.parentNode.removeChild(script);
    }

    this.fetchJS(head.querySelectorAll('script'), document.head);
    this.fetchJS(clientDoc.body.querySelectorAll('script'), document.head);

    for (const inlineStyling of head.querySelectorAll('style')) {
      let newInlineStyles = document.createElement('style');
      if (inlineStyling.id) {
        newInlineStyles.id = inlineStyling.id;
      }
      newInlineStyles.textContent = inlineStyling.textContent;
      document.head.appendChild(newInlineStyles);
    }

    for (const linkStyle of head.querySelectorAll('link')) {
        if (linkStyle.rel == 'stylesheet') {
          document.head.appendChild(linkStyle);
        }
    }

    for (const inlineStyling of clientDoc.body.querySelectorAll('style')) {
      let newInlineStyles = document.createElement('style');
      if (inlineStyling.id) {
        newInlineStyles.id = inlineStyling.id;
      }
      newInlineStyles.textContent = inlineStyling.textContent;
      document.head.appendChild(newInlineStyles);
    }

    element.innerHTML = clientDoc.body.innerHTML;
  }

  fetchJS(scripts, sectionToAppendTo) {
    for (const script of scripts) {
      let newScript = document.createElement('script');
      if (script.id) {
        newScript.id = script.id;
      }
      newScript.classList.add('client-script');
      if (script.src) {
        newScript.src = script.src;
      } else {
        // don't add duplicate GTM script
        if (script.textContent.includes(`src='https://www.googletagmanager.com/gtm.js?id=`)) {
          continue;
        }
        newScript.textContent = script.textContent;
      }
      sectionToAppendTo.appendChild(newScript);
    }
  }
}