class DebugMode {
  param: string

  constructor (param = 'xYextDebug') {
    this.param = param;

    if (this.hasQueryParam()) {
      this.enable();
    }
  }

  hasQueryParam(): boolean {
    if ('URL' in (<any> window) && typeof URL === "function") {
      let params = new URL((<any> window).location.href).searchParams;
      return params && params.get(this.param) == 'true';
    }
    return false;
  }

  enable(): void {
    document.documentElement.classList.add(this.param);
  }

  disable():void {
    document.documentElement.classList.remove(this.param);
  }

  isEnabled(): boolean {
    const d: HTMLElement = <HTMLElement> document.documentElement;
    return d.classList.contains(this.param);
  }
}

export let Debug = new DebugMode();
